<template>
  <div class="page home-page bg-primary-800 min-h-footer w-full pb-20 text-white pt-8" ref="page">
    <div class="w-page my-0 mx-auto page-padding">
      <h1 class="font-title text-4xl">Mon panier</h1>
      <div class="flex items-center">
        <template>
          <div class="italic m-auto ml-0">
            <span v-if="cartCount > 1">{{ cartCount }} modules dans le panier</span>
            <span v-else-if="cartCount === 1">{{ cartCount }} module dans le panier</span>
            <span v-else>aucun module dans le panier</span>
          </div>
        </template>
        <div class="m-auto mr-0">
          <router-link :to="{name: $routeNames.Search, params: {run: true}}"
                       class="flex flex-nowrap items-center hover:underline">
            <span class="m-auto mr-2">Continuer mes achats</span>
            <svg class="mx-0 my-auto" xmlns="http://www.w3.org/2000/svg" width="8.248" height="14.496"
                 viewBox="0 0 8.248 14.496">
              <path id="Path_24" data-name="Path 24" d="M0,5.834,5.834,0l5.834,5.834"
                    transform="translate(7.248 1.414) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"/>
            </svg>
          </router-link>
        </div>
      </div>
      <template v-if="loading">
        <div class="flex mt-8">
          <loader size="128px" class="m-auto"></loader>
        </div>
      </template>
      <template v-if="!loading">
        <!-- module in cart list -->
        <div class="module-cart-list mt-8">
          <div class="ml-10" v-if="modules.length > 0">
            <div class="mcl-header flex items-center mb-2">
              <span class="text-2xl text-white bold m-auto ml-0">Module</span>
              <span class="text-2xl text-white bold m-auto mr-0">Prix</span>
            </div>
            <div class="module-line flex relative items-center" v-for="(module, index) in modules" :key="module.id">
              <div class="delete-action -ml-10 absolute">
                <svg class="cursor-pointer" @click="handleRemoveModule(module.id)" xmlns="http://www.w3.org/2000/svg"
                     width="15.1" height="15.1" viewBox="0 0 15.1 15.1">
                  <path id="Union_2" data-name="Union 2"
                        d="M3981.318-2751.733l-5.2-5.2-5.2,5.2a1.378,1.378,0,0,1-1.949,0,1.377,1.377,0,0,1,0-1.949l5.2-5.2-5.2-5.2a1.376,1.376,0,0,1,0-1.948,1.376,1.376,0,0,1,1.949,0l5.2,5.2,5.2-5.2a1.379,1.379,0,0,1,1.949,0,1.378,1.378,0,0,1,0,1.948l-5.2,5.2,5.2,5.2a1.378,1.378,0,0,1,0,1.949,1.376,1.376,0,0,1-.975.4A1.373,1.373,0,0,1,3981.318-2751.733Z"
                        transform="translate(-3968.571 2766.429)" fill="#fff"/>
                </svg>
              </div>
              <div
                  class="module flex flex-col lg:flex-row bg-primary-900 p-4 border border-r-0 border-l-0 border-white flex w-full items-center"
                  :class="{'border-b-0': index !== modules.length - 1}">
                <div
                    class="picture my-auto ml-0 mr-4 rounded-xl bg-no-repeat bg-cover bg-center flex-shrink-0 flex-grow-0"
                    :style="`background-image: url(${module.picture.getPath({w: 700, h: 400, crop: true})})`"></div>
                <div class="informations my-auto ml-0 mr-4">
                  <router-link v-slot="{ navigate }" :to="{name: $routeNames.ModulePage, params: {slug: module.slug}}">
                    <div class="title clamp-2 bold text-2xl cursor-pointer hover:underline"
                         @click="navigate" role="link">{{ module.title }}</div>
                  </router-link>
                  <div class="font-light">
                    <template v-if="module.resourcesCount > 1">{{ module.resourcesCount }} ressources</template>
                    <template v-if="module.resourcesCount <= 1">{{ module.resourcesCount }} ressource</template>
                    •
                    <template v-if="module.hours <= 1">{{ module.hours }} heure de formation</template>
                    <template v-if="module.hours > 1">{{ module.hours }} heures de formations</template>
                    •
                    <template v-if="module.difficulty === 'expert'">Expert</template>
                    <template v-if="module.difficulty === 'intermediate'">Intermédiaire</template>
                    <template v-if="module.difficulty === 'beginner'">Débutant</template>
                  </div>
                </div>
                <div class="my-auto ml-auto mr-3">
                  <div class="price relative font-title text-4xl">
                    <span>{{ module.price.toFixed(2).replace('.', ',')}}</span>
                    <span class="text-xl absolute top-0">€</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="validation mt-4 flex flex-col lg:flex-row items-center">
              <div class="promo m-auto ml-0">
                <label for="promocode">Code Promotionnel</label>
                <span v-if="promoError" class="text-red-800 ml-8">{{ promoError }}</span>
                <form v-on:submit.stop.prevent="handleApplyPromoCode">
                  <div class="input-container mt-2 flex items-center">
                    <input class="rounded-l-full text-center px-4 py-2 outline-none text-black"
                           :disabled="promoLoading"
                           type="text" id="promocode" v-model="promoCode" placeholder="LODYLEARN10"/>
                    <button
                        @click="handleApplyPromoCode"
                        class="rounded-r-full bg-primary-500 cursor-pointer text-center px-4 py-2 hover:bg-white hover:text-primary-900 transition">
                      <span v-if="!promoLoading">Appliquer</span>
                      <span v-else>
                        <loader size="28px" class="m-auto"></loader>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
              <div class="booking mt-8 lg:mt-3 mx-0 lg:mt-auto lg:m-auto lg:mr-4 flex flex-col">
                <div class="price font-title text-4xl">
                  Prix total : <span class="relative mr-3">{{ total.toFixed(2).replace('.', ',') }}<span
                    class="absolute top-0 text-xl">€</span></span> TTC
                </div>
                <button @click="checkout"
                    class="bg-primary-500 px-4 py-2 rounded-full flex-grow-0 mx-auto lg:ml-auto lg:mr-0 mt-2 transition border-2 border-primary-500 hover:bg-white hover:border-primary-900 hover:text-primary-900">
                  Valider mon panier
                </button>
              </div>
            </div>
          </div>
          <div v-else></div>
        </div>
        <!-- end of module in cart list -->
        <!-- infobar -->
        <div class="infobar bg-primary-500 px-8 py-2 text-center mt-10 mb-10">
          <span>Notre équipe répond à vos questions du lundi au vendredi par email à l’adresse <strong>sav@lodylearn.fr</strong></span>
        </div>
        <!-- endof infobar -->
        <!-- pushed modules -->
        <div class="pushed-modules" v-if="homeModules.length > 0">
          <module-list
              bg="bg-primary-900"
              hour-bg="bg-primary-800"
              :use-bar="false"
              title-class="font-title text-xl"
              title="Modules qui pourraient vous intéresser" :modules="homeModules" class="mt-8"></module-list>
        </div>
        <!-- end of pushed modules -->
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Loader from '@/components/loader.vue';
import ModuleList from '@/components/module-list/module-list.vue';

@Component({
  name: 'ShoppingCart',
  components: {
    ModuleList,
    Loader,
  },
  data() {
    return {
      promoCode: '',
      promoLoading: false,
      promoError: '',
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.shoppingCart.loading || s.home.loading,
      modules: (s) => s.shoppingCart.fetchedModules,
      homeModules: (s) => s.shoppingCart.interestingModules,
    }),
    cartItems() {
      return this.$store.getters['shoppingCart/currentModules'];
    },
    total() {
      return this.cartItems.reduce((total, curr) => total + curr.price, 0);
    },
    cartCount() {
      return this.$store.getters['shoppingCart/articleCount'];
    },
  },
  watch: {
    cartCount() {
      this.$store.dispatch('shoppingCart/fetch');
    },
  },
  async mounted() {
    await Promise.all([
      await this.$store.dispatch('shoppingCart/fetch'),
      await this.$store.dispatch('shoppingCart/fetchInteresting'),
    ]);
  },
  methods: {
    async checkout() {
      await this.$router.push({ name: this.$routeNames.Payout });
    },
    handleApplyPromoCode() {
      this.promoLoading = true;
      this.promoError = '';
      setTimeout(() => {
        this.promoLoading = false;
        this.promoError = 'Code introuvable';
      }, 500);
    },
    handleRemoveModule(id) {
      this.$store.commit('shoppingCart/REMOVE_MODULE', id);
    },
  },
})
export default class ShoppingCart extends Vue {
}
</script>

<style scoped>
.picture {
  width: 194px;
  height: 120px;
  background-color: #eee;
}
</style>
